<template>
  <moe-page title="查看商品折扣">
    <moe-card :autoHeight="true">
      <moe-steps :active="active" :stepsList="[{ title: '基本信息设置' }, { title: '设置商品优惠' }, { title: '完成折扣活动' }]"></moe-steps>

      <moe-describe-list title="" :col="2">
        <moe-describe-item label="活动标签"> {{ goodsDiscountParams.tag }} </moe-describe-item>
        <moe-describe-item label="活动名称"> {{ goodsDiscountParams.name }} </moe-describe-item>
        <moe-describe-item label="优惠方式"> {{ $moe_format.getDiscountMethod(goodsDiscountParams.method) }} </moe-describe-item>
        <moe-describe-item label="优惠级别"> {{ $moe_format.getPresaleLevel(goodsDiscountParams.level) }} </moe-describe-item>
        <moe-describe-item label="活动时间">{{ goodsDiscountParams.startTime }} 至 {{ goodsDiscountParams.endTime }} </moe-describe-item>
        <moe-describe-item label="创建时间"> {{ goodsDiscountParams.createTime }} </moe-describe-item>
      </moe-describe-list>

      <div style="height: 700px;min-height: 700px;" class="df fdc">
        <moe-table
          :stripe="true"
          :numberShow="false"
          :data="goodsDiscountParams.configList"
          :mode="false"
          :showPage="false"
          :params="{ pageNum: 1 }"
          emptyText="未添加折扣商品"
          rowKey="goodsId">
          <el-table-column label="商品信息" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic">
                <moe-image :src="row.coverUrl" width="80px" height="80px" />
                <div style="text-align:left" class="ml-10">
                  <p class="fwb">{{ row.name }}</p>
                  <p class="font-12 color-info">商品ID: {{ row.goodsId }}</p>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="单价" min-width="200">
            <template slot-scope="{ row }">
              {{ `¥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}
            </template>
          </el-table-column>

          <el-table-column label="优惠方式" min-width="200" v-if="goodsDiscountParams.level === 'GOOD'">
            <div slot-scope="{ row }" class="df aic jcc">
              <div class="df aic" v-if="goodsDiscountParams.method === 'DISCOUNT'">
                <div>打</div>
                <div class="ml-5 mr-5">{{ row.discount }}</div>
                <div>折</div>
              </div>

              <div class="df aic" v-else-if="goodsDiscountParams.method === 'REDUCE'">
                <div>减</div>
                <div class="ml-5 mr-5">{{ row.discount }}</div>
                <div>元</div>
              </div>
            </div>
          </el-table-column>

          <el-table-column label="优惠方式" min-width="200" v-if="goodsDiscountParams.level === 'SKU'">
            <div slot-scope="{ row, $index }" class="df fdc aic jcc">
              <div>{{ $moe_format.getDiscountMethod(goodsDiscountParams.method) }}</div>
              <el-button
                v-if="$moe_math.mathGreaterThan(row.minDiscount, 0) && $moe_math.mathGreaterThan(row.minDiscount, 0)"
                @click="queryShopGoodsById(row, $index)"
                icon="el-icon-circle-check"
                type="text">
                优惠设置
              </el-button>
              <el-button
                v-else
                @click="queryShopGoodsById(row, $index)"
                icon="el-icon-thumb"
                type="text">
                优惠设置
              </el-button>
            </div>
          </el-table-column>

          <el-table-column label="优惠后价格" min-width="200">
            <template slot-scope="{ row }">
              {{ discountComputed(row) }}
            </template>
          </el-table-column>
        </moe-table>
      </div>

      <div class="df aic jcc">
        <el-button type="primary" plain icon="el-icon-back" @click="$moe_coordinator.navigateBack()">返回列表</el-button>
      </div>
    </moe-card>

    <moe-dialog :show="showDialog" title="SKU优惠设置" width="70%" @close="showDialog = false;">
      <div class="dialog_content">
        <moe-table
          url=""
          :maxHeight="480"
          :data="configList"
          :number-show="false"
          :mode="false"
          :params="{ pageNum: 1 }">
          <el-table-column label="商品编号" prop="goodsId" width="100" />

          <el-table-column label="SKU" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic jcc">
                <moe-tag class="mr-5" v-for="(item, index) in row.specValueStr.split(',')" :key="index">{{ item }}</moe-tag>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="库存" prop="stock" min-width="100" />

          <el-table-column label="单价" min-width="200">
            <template slot-scope="{ row }">
              {{ `¥ ${row.salePrice}` }}
            </template>
          </el-table-column>

          <el-table-column label="优惠方式" min-width="200">
            <div slot-scope="{ row }" class="df aic jcc">
              <div class="df aic" v-if="goodsDiscountParams.method === 'DISCOUNT'">
                <div>打</div>
                <div class="ml-5 mr-5">{{ row.discount }}</div>
                <div>折</div>
              </div>
              <div class="df aic" v-else-if="goodsDiscountParams.method === 'REDUCE'">
                <div>减</div>
                <div class="ml-5 mr-5">{{ row.discount }}</div>
                <div>元</div>
              </div>
            </div>
          </el-table-column>

          <el-table-column label="优惠后价格" min-width="200">
            <template slot-scope="{ row }">
              {{ skuDiscountComputed(row) }}
            </template>
          </el-table-column>
        </moe-table>
      </div>
      <template slot="footer">
        <el-button @click="showDialog = false;">关闭</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageGoodsDiscountDetail',
  data() {
    return {
      active: 2,
      goodsDiscountParams: {
        id: '',
        tag: '',
        name: '',
        method: 'DISCOUNT',
        level: 'GOOD',
        startTime: '',
        endTime: '',
        createTime: '',
        configList: [],
      },
      showDialog: false,
      configList: [],
    }
  },
  methods: {
    /** 优惠后价格计算 */
    discountComputed(row) {
      if (this.goodsDiscountParams.level === 'GOOD') {
        if (this.goodsDiscountParams.method === 'DISCOUNT') {
          let minPrice = this.$moe_math.mathDiv(Math.ceil(this.$moe_math.mathMul(this.$moe_math.mathMul(row.minPrice, this.$moe_math.mathDiv(row.discount, 10)), 100)), 100);
          let maxPrice = this.$moe_math.mathDiv(Math.ceil(this.$moe_math.mathMul(this.$moe_math.mathMul(row.maxPrice, this.$moe_math.mathDiv(row.discount, 10)), 100)), 100);
          return `¥ ${minPrice} ${this.$moe_math.mathGreaterThan(maxPrice, minPrice) ? `- ¥ ${maxPrice}` : ''}`;
        } else if (this.goodsDiscountParams.method === 'REDUCE') {
          let minPrice = this.$moe_math.mathSub(row.minPrice, row.discount);
          let maxPrice = this.$moe_math.mathSub(row.maxPrice, row.discount);
          return `¥ ${minPrice} ${this.$moe_math.mathGreaterThan(maxPrice, minPrice) ? `- ¥ ${maxPrice}` : ''}`;
        }
      } else if (this.goodsDiscountParams.level === 'SKU') {
        return `¥ ${row.minDiscount} ${this.$moe_math.mathGreaterThan(row.maxDiscount, row.minDiscount) ? `- ¥ ${row.maxDiscount}` : ''}`;
      }
    },
    /** sku优惠后价格计算 */
    skuDiscountComputed(row) {
      if (this.goodsDiscountParams.method === 'DISCOUNT') {
        return `¥ ${this.$moe_math.mathDiv(Math.ceil(this.$moe_math.mathMul(this.$moe_math.mathMul(row.salePrice, this.$moe_math.mathDiv(row.discount, 10)), 100)), 100)}`;
      } else if (this.goodsDiscountParams.method === 'REDUCE') {
        return `¥ ${this.$moe_math.mathSub(row.salePrice, row.discount)}`;
      }
    },
    /** 查询商品详情 */
    queryShopGoodsById(row, index) {
      this.$moe_api.GOODS_API.queryShopGoodsById({ id: row.goodsId }).then((data) => {
        if (data.code === 200) {
          let goodsItem = this.$moe_lodash.cloneDeepData(data.result.goodsItem);
          if (this.goodsDiscountParams.configList[index].configList.length) {
            this.configList = goodsItem.filter(({ delFlag }) => delFlag === 0).map((item) => {
              item.goodsItemId = item.id;
              let findItem = this.goodsDiscountParams.configList[index].configList.find(({ goodsItemId }) => item.goodsItemId === goodsItemId);
              return {
                ...item,
                discount: findItem.discount || '0',
                id: findItem.id || ''
              }
            })
          } else {
            this.configList = goodsItem.filter(({ delFlag }) => delFlag === 0).map((item) => {
              return {
                ...item,
                goodsItemId: item.id,
                minPrice: data.result.minPrice,
                maxPrice: data.result.maxPrice,
                id: '',
                discount: '',
              }
            });
          }
          this.showDialog = true;
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 获取折扣活动详情 */
    getDiscountDetail() {
      if (this.$route.query.id) {
        this.$moe_api.GOODS_DISCOUNT.getDiscountDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            let r = data.result;
            this.goodsDiscountParams = {
              id: r.id,
              tag: r.tag,
              name: r.name,
              method: r.method,
              level: r.level,
              startTime: r.startTime,
              endTime: r.endTime,
              createTime: r.createTime,
              configList: [],
            };

            if (r.configList.length) {
              this.getQueryShopGoodsByIds(r.configList);
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
    /** 根据商品ID获取商品列表 */
    getQueryShopGoodsByIds(goodsList) {
      //数组去重
      let unionByList = this.$moe_lodash.unionByList(goodsList, goodsList, 'goodsId');
      this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: unionByList.map(({ goodsId }) => goodsId).join(',') }).then((data) => {
        if (data.code === 200) {
          let result = data.result;
          switch (this.goodsDiscountParams.level) {
            case 'GOOD':
              this.goodsDiscountParams.configList = unionByList.map((item) => {
                let findGoodsItem = result.find(({ id }) => item.goodsId === id);
                return {
                  ...findGoodsItem,
                  goodsId: findGoodsItem.id,
                  ...item,
                }
              })
              break;
            case 'SKU':
              this.goodsDiscountParams.configList = unionByList.map((item) => {
                let findGoodsItem = result.find(({ id }) => item.goodsId === id);
                let configList = goodsList.filter(({ goodsId }) => item.goodsId === goodsId).map((goodsListItem) => {
                  return {
                    ...goodsListItem,
                  }
                })

                let minDiscount = 0, maxDiscount = 0;
                if (this.goodsDiscountParams.method === 'DISCOUNT') {
                  minDiscount = configList.map(({ discount }) => this.$moe_math.mathDiv(Math.ceil(this.$moe_math.mathMul(this.$moe_math.mathMul(findGoodsItem.minPrice, this.$moe_math.mathDiv(discount, 10)), 100)), 100)).sort((a, b) => a - b)[0];
                  maxDiscount = configList.map(({ discount }) => this.$moe_math.mathDiv(Math.ceil(this.$moe_math.mathMul(this.$moe_math.mathMul(findGoodsItem.maxPrice, this.$moe_math.mathDiv(discount, 10)), 100)), 100)).sort((a, b) => b - a)[0];
                } else if (this.goodsDiscountParams.method === 'REDUCE') {
                  minDiscount = this.$moe_math.mathSub(findGoodsItem.minPrice, configList.map(({ discount }) => Number(discount)).sort((a, b) => a - b)[0]);
                  maxDiscount = this.$moe_math.mathSub(findGoodsItem.maxPrice, configList.map(({ discount }) => Number(discount)).sort((a, b) => b - a)[0]);
                }

                return {
                  ...findGoodsItem,
                  goodsId: findGoodsItem.id,
                  ...item,
                  configList: configList.length ? configList : [],
                  minDiscount,
                  maxDiscount
                }
              })
              break;
          }
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
  },
  mounted() {
    this.getDiscountDetail();
  }
}
</script>

<style lang="scss">
</style>